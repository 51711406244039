<template>
  <div class="page">
    <!-- <div class="inside-page-content">
    <div class="paper">-->
    <div style="display:flex;align-items: stretch;padding: 0 40px;">
      <img
        src="http://123.249.70.34:8081/api/profile/upload/2023/01/05/11111_20230105142008A001.jpg"
        style="min-width:100px;flex:1;margin-right: 50px;height: 440px;object-fit: cover;"
      />
      <div
        style="min-width:100px;display: flex;flex-direction: column;justify-content: space-between;flex:1;padding: 20px 0;"
      >
        <div>{{detailData.title}}</div>
        <div class="h3 sub-title">收费标准：{{detailData.charges}}/次</div>
        <div class="h3 sub-title">联系人：{{detailData.contactName}}</div>
        <div class="h3 sub-title">电话：{{detailData.contactPhone}}</div>
        <div class="h3 sub-title">地址：{{detailData.address}}</div>
        <div class="detail-button" @click="apply">申请服务</div>
      </div>
    </div>
    <div class="divider-bottom"></div>

    <div class="notice-title h1">{{detailData.title}}简介</div>
    <div class="notice-content" v-html="detailData.content"></div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      article_id: null,
      detailType: 'environment',
      detailData: {
        'title': '',
        'content': ''
      },
    }
  },
  computed: {

  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const article_id = this.$route.query?.id
    if (article_id) {
      this.article_id = article_id
    }
    const detailType = this.$route.query?.detailType
    if (detailType) {
      this.detailType = detailType
    }
    if (this.detailType == 'ds-environment') {
      this.$api.digitalServices.environmentDetail({ 'id': this.article_id }).then((res) => {
        //console.log('res = ', res)
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'ds-calculation') {
      this.$api.digitalServices.calculationDetail({ 'id': this.article_id }).then((res) => {
        //console.log('res = ', res)
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'ds-cs') {
      this.$api.digitalServices.csDetail({ 'id': this.article_id }).then((res) => {
        //console.log('res = ', res)
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {
    apply() {
      if (this.detailType == 'ds-environment') {
        this.$router.push({ path: '/digitalServices/page-hjsy/applyservice', query: { id: this.article_id, 'name': this.detailData.title, 'detailType': this.detailType } })

      }
      else if (this.detailType == 'ds-calculation') {
        this.$router.push({ path: '/digitalServices/page-js/applyservice', query: { id: this.article_id, 'name': this.detailData.title, 'detailType': this.detailType } })

      }
      else if (this.detailType == 'ds-cs') {
        this.$router.push({ path: '/digitalServices/page-slfw/applyservice', query: { id: this.article_id, 'name': this.detailData.title, 'detailType': this.detailType } })

      }
    }
  }
}
</script>
<style scoped lang="less">
.page {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
}
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  text-align: center;
  margin: 10px 0;
  // padding-bottom: 30px;
  // border-bottom: 2px dashed #cccccc;
}

.divider-bottom {
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #cccccc;
  margin: 20px 0;
}

.notice-content {
  margin-top: 30px;
  line-height: 40px;
}
.sub-title {
  color: #908d8d;
}
.detail-button {
  text-align: center;
  height: 40px;
  width: 150px;
  border: solid 1px #dfdfdf;
  line-height: 40px;
  cursor: pointer;
  border-radius: 15px;
  background-color: #0046c0;
  color: #fff;
}
</style>
